<template>
    <div>
        <h1>Morale</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results @enterKeyPressed="submitFeedback" />
        </form>
    </div>
</template>
<script>
    import { store } from '@/store/Report.store.js'
    import Options from "./morale_opt";
    import GroupBy from "./utils/GroupBy";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    import helpers from "@/store/helpers";

    export default {
        name: 'morale',
        components: {Results, Fields, GroupBy, Options},
        data() {
            return {
                optionFields: {
                    startDate: {val: new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString(), qsField: '[dates][morale_date_from]'},
                    endDate: {val: new Date().toLocaleDateString(), qsField: '[dates][morale_date_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][s.cdi_location_id]'},
                    shifts: {val: 0, qsField: '[numbers][s.user_staff_shift_id]'},
                    area: {val: -1, qsField: '[znumbers][production]'},
                    shiftType: {val: '', qsField: '[fstrings][user_staff_shift]'},
                    title: {val: '', qsField: '[strings][title]'}
                },
                groupByFields: {
                    user_staff_shift: {label: 'Shift', checked: false},
                    cdi_location: {label: 'Location', checked: true},
                    production: {label: 'Area', checked: false}
                },
                dataFields: {
                    cdi_location: {label: 'Location', checked: true},
                    morale: {label: 'Morale', checked: true},
                    date: {label: 'Date', checked: true}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            },
            submitFeedback(e) {
                if (e.className.split(' ').indexOf('feedback_reply') != -1) {
                    e.blur();
                    let name = e.getAttribute('name');
                    let reply = e.value;
                    store.customAPI('mycdi/save_feedback_reply', helpers.getQSPairs({
                        cdiFeedbackId: {val: name, qsField: '[cdi_feedback_id]'},
                        feedbackReply: {val: reply, qsField: '[feedback_reply]'}
                    }));
                }
            }
        },
        created() {
            this.$appStore.setTitle(['Morale']);
            store.state.isGroupBy = true;
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>