<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date From</label>
                <DatePicker v-model="state.optionFields.startDate.val" :input-attributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date To</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shifts</label>
                <select class="form-control" v-model="state.optionFields.shifts.val">
                    <option value="0">All</option>
                    <option v-for="[id, shift] in cache.shiftCache.SHIFTS" v-bind:key="id" :value="id">{{ shift }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Area</label>
                <select class='form-control' v-model="state.optionFields.area.val">
                    <option value='-1'>All</option>
                    <option value=0>Office</option>
                    <option value=1>Production</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shift Type</label>
                <select class='form-control' v-model="state.optionFields.shiftType.val">
                    <option value=''>All</option>
                    <option value='1st'>1st Shift</option>
                    <option value='2nd'>2nd Shift</option>
                    <option value='Weekend'>Weekend</option>
                    <option value='Temporary'>Temporary</option>
                    <option value='Salary'>Salary</option>
                    <option value='Office Hourly'>Office Hourly</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Title</label>
                <select class="form-control" v-model="state.optionFields.title.val">
                    <option value="">All</option>
                    <option v-for="[index, title] in cache.userCache.EMPLOYEE_TITLES" v-bind:key="index" :value="title">{{ title }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import shiftCache from '@/cache/shift.cache.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import userCache from '@/cache/user.cache.js';
    import DatePicker from "../utils/DatePicker";
    export default {
        name: 'Options',
        components: {DatePicker},
        data() {
            return {
                state: store.state,
                cache: {
                    shiftCache,
                    cdiLocationCache,
                    userCache
                }
            }
        }
    }
</script>